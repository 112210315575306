import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "../../store"
import { setStep } from "../../store/slices/fishingSlice"

const PdfPage = () => {
  const dispatch = useAppDispatch()
  const voucherId = useParams().id

  const [pdfUrl, setPdfUrl] = useState("")

  useEffect(() => {
    dispatch(setStep("04"))
  }, [dispatch])

  useEffect(() => {
    const getVoucherPdf = async (id: string, retries = 3) => {
      try {
        const response = await fetch(
          `https://poymay.kz/api/uploads/report-${id}.pdf`,
          {
            headers: {
              Accept: "application/pdf"
            }
          }
        )
        if (response.ok) {
          const blob = await response.blob()
          if (blob) {
            const url = URL.createObjectURL(blob)
            setPdfUrl(url)
          }
        } else {
          throw new Error("Network response was not ok")
        }
      } catch (error) {
        console.error("Error fetching PDF:", error)
        if (retries > 0) {
          console.log(`Retrying... ${retries} retries left`)
          await new Promise(resolve => setTimeout(resolve, 3000))
          await getVoucherPdf(id, retries - 1)
        } else {
          console.error("Maximum retries exceeded, unable to fetch PDF")
        }
      }
    }

    if (voucherId) {
      getVoucherPdf(voucherId.toString())
    }
  }, [voucherId])

  useEffect(() => {
    const anchorEl = document.getElementById("pdf")
    if (pdfUrl && anchorEl) {
      anchorEl.click()
    }
  }, [pdfUrl])

  console.log("pdfUrl:", pdfUrl)

  return (
    <div>
      <h1>Ваша путевка отправлена на указанную вами Электронную почту. </h1>
      <br />
      <p style={{ fontSize: "16px" }}>
        Дождитесь автоматического скачивания путевки. Если путевка автоматически
        не скачалась можете скачать ее по ссылке:{" "}
        <a id="pdf" href={pdfUrl} download>
          Скачать путевку
        </a>
      </p>
      <h2 style={{ marginTop: "20px", fontWeight: 500, fontSize: "20px" }}>
        Приятного отдыха и удачной рыбалки!{" "}
      </h2>
    </div>
  )
}

export default PdfPage
